import React, { useContext, useEffect, useState } from "react";
import BurgerMenu from "./BurgerMenu";
import ClickAwayListener from "react-click-away-listener";
import axios from "axios";
import { SERVER_URL } from "../config";
import paymentQr from "../Assets/Payment.png";
import { userDataContext } from "../context/UserDataContext";
import { toast } from "react-toastify";

const Premium = () => {
  const { token } = useContext(userDataContext);
  const [showPremiumPopup, setShowPremiumPopup] = useState(false);
  const [premiumTxHash, setPremiumTxHash] = useState("");
  const handleVerifyTx = async () => {
    if (token) {
      axios
        .post(
          SERVER_URL + "/payments/verifyTransaction",
          {
            hash: premiumTxHash,
            type: "usdt",
          },
          {
            headers: {
              authorization: token,
            },
          }
        )
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
          toast("unable to verify tx", { type: "error" });
        });
    } else {
      toast("unable to verify tx due to login issue", { type: "error" });
    }
  };

  return (
    <>
      <BurgerMenu>
        <div className="lg:w-[768px] lg:mx-auto lg:mt-7">
          <div className="flex flex-col w-full gap-4 ">
            <span className="text-3xl font-bold text-second">
              Boosted Minting
            </span>
            <div className="w-full flex flex-col gap-4">
              <div className="flex w-full gap-4">
                <div className="bg-primary text-second bg-[#CB202D] font-bold w-1/2 text-center py-12 rounded-xl">
                  Ignite - 2 USDT
                </div>
                <div className="bg-primary text-second bg-[#CB202D] font-bold w-1/2 text-center py-12 rounded-xl">
                  Boost - 5 USDT
                </div>
              </div>
              <div className="flex w-full gap-4">
                <div className="bg-primary text-second bg-[#CB202D] font-bold w-1/2 text-center py-12 rounded-xl">
                  Elite - 10 USDT
                </div>
                {/* <div className="bg-primary text-second bg-[#CB202D] font-bold w-1/2 text-center py-12 rounded-xl">
								Ultra - 30 USDT
							</div> */}
              </div>
            </div>
          </div>
          <button
            onClick={() => setShowPremiumPopup(true)}
            className="bg-primary text-second font-bold w-full mt-8 py-4 text-center rounded-xl bg-[#CB202D]"
          >
            Start Boost Minting Now!
          </button>
          <div className="mt-8">
            <p className="text-[16px] font-bold mb-4 text-first">
              Benefits of Boosted Minting
            </p>
            <p className="text-[16px] font-bold text-second">
              1. Automated Minting, Effortlessly Earn
            </p>
            <p className="pl-4 text-[12px]  text-second">
              Boosted Minting simplifies your daily routine by eliminating the
              need to manually claim rewards. With automation, your minting
              continues seamlessly in the background, ensuring you never miss
              out on earnings due to forgetfulness or busy schedules.
            </p>
            <p className="text-[16px] mt-4 font-bold text-second">
              2. Reliable Automation, Peace of Mind
            </p>
            <p className="pl-4 text-[12px] text-second">
              Enjoy the peace of mind that comes with a fully automated minting
              system. Once set up, your minting operations run continuously
              without any manual intervention. This reliability allows you to
              focus on other tasks while your earnings grow effortlessly.
            </p>
            <p className="text-[16px] mt-4 font-bold text-second">
              3. Never Miss Out, Even When You Forget
            </p>
            <p className="pl-4 text-[12px] text-second">
              Say goodbye to the worry of forgetting to activate minting. With
              Boosted minting, automated processes ensure uninterrupted
              operations, even if you forget to log in. Your earnings continue
              to accrue, ensuring you maximize every opportunity, hassle-free.
            </p>
          </div>
        </div>
      </BurgerMenu>

      {showPremiumPopup && (
        <div className="fixed h-screen w-screen z-50 top-0 flex justify-center items-center bg-black bg-opacity-35">
          <ClickAwayListener
            onClickAway={(e) => {
              console.log("first");
              setShowPremiumPopup(false);
            }}
            className="h-fit"
          >
            <div className="h-fit w-[90%] bg-white p-8 rounded-lg flex flex-col gap-4">
              <p className="break-words">
                Send the USDT to the below address depending on your plan and
                verify the transaction hash <br />
                <strong>0x919Bd02f544EA1a499A00b75A6F8DB17421B9D5f</strong>
              </p>
              <img src={paymentQr} className="w-1/2" />
              <p>Enter Transaction Hash below:</p>
              <input
                type="text"
                className="border border-black font-bold w-full rounded-xl p-2"
                onChange={(e) => setPremiumTxHash(e.target.value)}
                value={premiumTxHash}
              />
              <button
                onClick={handleVerifyTx}
                className="bg-primary text-second font-bold w-full text-center py-2 rounded-xl"
              >
                Verify Transaction
              </button>
            </div>
          </ClickAwayListener>
        </div>
      )}
    </>
  );
};

export default Premium;

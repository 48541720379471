
import React from 'react'

function Xaccount({ ...props }) {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M22.9079 2.37988H27.1247L17.9121 12.9092L28.75 27.2373H20.264L13.6175 18.5474L6.01243 27.2373H1.79304L11.6468 15.975L1.25 2.37988H9.95139L15.9592 10.3228L22.9079 2.37988ZM21.4279 24.7134H23.7645L8.68174 4.7713H6.17433L21.4279 24.7134Z" fill="#000000" />
        </svg>

    )
}

export default Xaccount

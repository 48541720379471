import React, { useContext, useEffect } from "react";
import BurgerMenu from "./BurgerMenu";
import Home from "./Home";
import { toast } from "react-toastify";
import { SERVER_URL } from "../config";
import axios from "axios";
import { userDataContext } from "../context/UserDataContext";
import { useNavigate } from "react-router-dom";

const MainScreen = () => {
	const navigate = useNavigate();
	const { setUser, setTeam, setRewards, setToken } =
		useContext(userDataContext);
	const getTokenFromLocalStorage = async () => {
		const userToken = localStorage.getItem("userToken");
		if (userToken) {
			const verifyTokenApiRes = await axios
				.post(
					`${SERVER_URL}/users/verify-token`,
					{},
					{
						headers: {
							Authorization: `Bearer ${userToken}`,
						},
					}
				)
				.catch(() => {
					toast("Please Relogin!");
					localStorage.removeItem("userToken");
					navigate("/banner");
				});
			console.log(verifyTokenApiRes?.data?.data);
			setUser(verifyTokenApiRes?.data?.data);

			const teamApiRes = await axios
				.get(`${SERVER_URL}/users/team`, {
					headers: {
						Authorization: `Bearer ${userToken}`,
					},
				})
				.catch(() => {
					toast("Teams not found!");
					// localStorage.removeItem("userToken");
				});
			setTeam(teamApiRes.data.data);

			const rewardsApiRes = await axios
				.get(`${SERVER_URL}/users/team-rewards`, {
					headers: {
						Authorization: `Bearer ${userToken}`,
					},
				})
				.catch(() => {
					toast("Rewards not found!");
					// localStorage.removeItem("userToken");
				});
			console.log(rewardsApiRes?.data);
			setRewards(rewardsApiRes?.data?.data);
			console.log("userToken", userToken);
			setToken(userToken);
		} else {
			navigate("/banner");  
		}
	};

	useEffect(() => {
		getTokenFromLocalStorage();
	}, []);

	return (
		<BurgerMenu>
			<div className="h-full flex lg:w-[768px] lg:mx-auto lg:mt-7 flex-col justify-between">
				<Home />
			</div>
		</BurgerMenu>
	);
};

export default MainScreen;

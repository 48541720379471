import React from "react";

import GoogleImg from "../Assets/Bannerpage/googleImg";
import FacebookImg from "../Assets/Bannerpage/facebookImg";
import TelegramImg from "../Assets/Bannerpage/telegramImg";
import AppleImg from "../Assets/Bannerpage/appleImg";
import Logo from "../Assets/Logo";
import BgVideo from "../Assets/bgvideo.mp4"

const Banner = () => {
  return (
    <>
      {/* <video src={BgVideo} width="320" height="240" type="video/mp4" autoPlay loop className="absolute z-[-10] w-full h-full object-fill"></video> */}
      <div className=" mx-auto  max-[320px]:max-w-[280px] max-[375px]:max-w-[300px]  max-w-[354px] ">
        <Logo
          className={
            "max-[320px]:max-w-[280px] max-[375px]:max-w-[300px] m-auto h-auto max-w-[354px]"
          }
        />
        <div className="  mx-[auto]">
          <p className="text-[32px] max-[320px]:text-[22px] max-[375px]:text-[26px]  text-center font-bold text-black">
            Mine Smarter & Earn More with{" "}
            <span className="text-first">Red Sale</span>
          </p>
        </div>
        <div className=" text-center mx-auto">
          <a
            href="/signup"
            className="cursor-pointer w-full mt-[21px] bg-[#CB202D] px-3 py-[5px] border-2 rounded-[8px] max-[320px]:text-[16px] max-[375px]:text-[20px]  text-[24px] font-bold text-[#F4F4F2] flex items-center justify-center"
          >
            Sign Up With email
          </a>
        </div>
        {/* <div className="mt-[29px]  flex justify-center items-center gap-2   text-center  mx-[auto]">
          <span className='relative after:content-[""] after:absolute after:h-[2px] after:w-full after:left-full after:ml-1 after:top-[50%] after:bg-[#98A2B3] after:translate-y-[-50%] before:content-[""] before:absolute before:h-[2px] before:w-auto before:right-full before:mr-1 before:top-[50%] before:bg-[#98A2B3] before:translate-y-[-50%]'>
              Or
            </span>
          <div className="w-full h-[1px] bg-[#98A2B3]" />
          <span>Or</span>
          <div className="w-full h-[1px] bg-[#98A2B3]" />
        </div> */}
        {/* <div className="flex mt-[20px] items-center justify-between  mx-[auto] ">
        

          <GoogleImg
            className={
              "max-[320px]:max-w-[54px] max-[375px]:max-w-[56px] max-[320px]:max-h-[54px]  max-w-[60px] max-h-[60px]"
            }
          />
          <FacebookImg
            className={
              "max-[320px]:max-w-[54px] max-[375px]:max-w-[56px] max-[320px]:max-h-[54px]  max-w-[60px] max-h-[60px]"
            }
          />
          <TelegramImg
            className={
              "max-[320px]:max-w-[54px] max-[375px]:max-w-[56px] max-[320px]:max-h-[54px]  max-w-[60px] max-h-[60px]"
            }
          />
          <AppleImg
            className={
              "max-[320px]:max-w-[54px] max-[375px]:max-w-[56px] max-[320px]:max-h-[54px]  max-w-[60px] max-h-[60px]"
            }
          />
        </div> */}
        <p className="max-[320px]:text-[12px]  mx-[auto] text-[16px] pl-1 mt-[25px] text-normal text-black leading-[24px] tracking-wide ">
          You have an account?{" "}
          <a href="/login" className="cursor-pointer text-first">
            Sign in now
          </a>
        </p>
      </div>
    </>
  );
};

export default Banner;

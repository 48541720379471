import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import BackArrow from '../Assets/BackArrow'
import BgVideo from "../Assets/bgvideo.mp4"
import Xaccount from "../Assets/Support/Xaccount"


const Support = () => {
    const navigate = useNavigate()

    return (
        <>
            <div className='block bg-[#F4F4F2] h-[100%] px-[25px] relative z-20 '>
            
            {/* <video src={BgVideo} type="video/mp4" autoPlay loop className="absolute z-[-10] w-full h-full object-fill top-0 left-0  overflow-scroll"></video> */}
                <div className='block pt-[50px]'>
                    <div className='flex items-center  relative'>
                        <button onClick={() => navigate("/")} className='absolute left-0 top-[50%] translate-y-[-50%] ' ><BackArrow/></button>
                        <h4 className='block text-center w-full text-[24px] font-bold text-first lg:border-b-2 lg:border-black lg:pb-4'>Support</h4>
                    </div>
                    <div className='mt-[50px] px-[8px] block lg:w-[50%] lg:mx-auto'>
                        <h5 className='text-[24px] font-bold text-second text-left' >
                            Support Channels of <span className='text-first '>Redsale </span>
                            Token
                        </h5>
                        <ul className='mt-[20px]'>
                           
                            <li><Link to={'https://x.com/RedSale369?t=peBLqR7qazVG1jTo6sxewg&s=09'} className='flex  items-center border-[1px] my-5 px-2 py-2 rounded-[10px] border-[#98A2B3]'><Xaccount className="mr-4"/><span className='text-[17px] font-semibold text-second'>X Account</span></Link></li>
                            <li><Link to={'https://t.me/RedSaleToken'} className='flex  items-center border-[1px] my-5 px-2 py-2 rounded-[10px] border-[#98A2B3]'><img src="/Assets/Support/Twitter.png" alt="" className='mr-4 w-[30px] h-[30px]' /><span className='text-[17px] font-semibold text-second'>Telegram Channel </span></Link></li>
                            <li><Link to={'https://t.me/Redsaleapp'} className='flex  items-center border-[1px] my-5 px-2 py-2 rounded-[10px] border-[#98A2B3]'><img src="/Assets/Support/Twitter.png" alt="" className='mr-4 w-[30px] h-[30px]' /><span className='text-[17px] font-semibold text-second'>Telegram Group</span></Link></li>
                            <li><Link to={'mailto:Info@redsale.app'} className='flex  items-center border-[1px] my-5 px-2 py-2 rounded-[10px] border-[#98A2B3]'><img src="/Assets/Support/Mail.png" alt="" className='mr-4 w-[30px] h-[30px]' /><span className='text-[17px] font-semibold text-second'>info@redsale.app</span></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Support
/* global BigInt */
import axios from "axios";
import { ethers } from "ethers";
import React, { useContext, useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { toast } from "react-toastify";
import Fram1 from "../Assets/WalletPage/Fram1";
import Fram2 from "../Assets/WalletPage/Fram2";
import Fram3 from "../Assets/WalletPage/Fram3";
import Fram4 from "../Assets/WalletPage/Fram4";
import BurgerMenu from "./BurgerMenu";
import { userDataContext } from "../context/UserDataContext";

const Wallet = () => {
  const { user } = useContext(userDataContext);
  const [Bnbprice, setBnbprice] = useState(null);
  const [Usdtprice, setUsdtprice] = useState(null);

  useEffect(() => {
    const BnbfetchPrice = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd"
        );
        setBnbprice(response.data.binancecoin.usd);
      } catch (error) {
        console.error("Error fetching BNB price:", error);
      }
    };
    BnbfetchPrice();
  }, []);

  useEffect(() => {
    const UsdtfetchPrice = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd"
        );
        setUsdtprice(response.data.tether.usd);
      } catch (error) {
        console.error("Error fetching USDT price:", error);
      }
    };
    UsdtfetchPrice();
  }, []);

  return (
    <>
      <BurgerMenu>
        <div className="block relative bg-[#C7202D] h-full lg:pt-7">
          <div className="h-[79%]  w-full bg-[#F4F4F2] absolute bottom-0 rounded-t-[30px] z-20 overflow-hidden ">
            {/* <video
							src={BgVideo}
							type="video/mp4"
							autoPlay
							loop
							className="absolute z-[-10] w-full h-full object-fill top-0 left-0  overflow-scroll"
						></video> */}
          </div>

          <div className="flex-col lg:w-[768px] lg:mx-auto  items-center text-center py-4 px-5 ">
            <div className="block  ">
              <div className=" text-third text-center">
                {/* <img
									src="/Assets/Walletpage/visa-logo.png"
									alt=""
								/>
								<p className="flex item-center text-[16px] font-semibold mt-[10px]">
									**** **** **** ****{" "}
									<img
										src="/Assets/Walletpage/eye.png"
										alt=""
										className="ml-3 w-[20px]"
									/>
								</p> */}
                <span className="block mt-[8px] text-[16px] font-medium">
                  Available Balance
                </span>
                <span className="block mt-[8px] text-[24px] font-bold">
                  {user?.balance &&
                    parseFloat(
                      ethers?.formatEther(BigInt(user?.balance))
                    ).toFixed(4)}{" "}
                  RST
                </span>
              </div>
            </div>

            <div className="flex justify-between text-first shadow-md bg-white rounded-2xl border-black  mx-[10px] mt-[31px] relative z-20 bottom-2 pb-1">
              <div
                className="flex-col w-[25%] items-center "
                onClick={() => toast("Coming Soon!")}
              >
                <div className="w-[50px] h-[50px]  flex justify-center items-center mx-auto">
                  <Fram1 />
                </div>
                <span className="text-[10px] font-medium ">Send</span>
              </div>
              <div
                className="flex-col w-[25%] items-center "
                onClick={() => toast("Coming Soon!")}
              >
                <div className="w-[50px] h-[50px]  flex justify-center items-center mx-auto">
                  <Fram2 />
                </div>
                <span className="text-[10px] font-medium ">Receive</span>
              </div>
              {/* <div
									className="flex-col"
									onClick={() => toast("Coming Soon!")}
								>
									<div className="w-[50px] h-[50px]  flex justify-center items-center ">
										<img
											src="/Assets/WalletPage/Vector3.png"
											alt=""
											className=" w-[20px]"
										/>
									</div>
									<span className="text-[10px] font-medium ">
										History
									</span>
								</div> */}
              <div
                className="flex-col w-[25%] items-center "
                onClick={() => toast("Coming Soon!")}
              >
                <div className="w-[50px] h-[50px]  flex justify-center items-center mx-auto">
                  <Fram3 />
                </div>
                <span className="text-[10px] font-medium ">Withdraw</span>
              </div>
              <div
                className="flex-col w-[25%] items-center justify-center"
                onClick={() => toast("Coming Soon!")}
              >
                <div className="w-[50px] h-[50px]  flex justify-center items-center mx-auto">
                  <Fram4 />
                </div>
                <span className="text-[10px] font-medium ">Airdrop</span>
              </div>
            </div>

            <div className="block  mt-[35px] relative z-20">
              <div className="flex justify-between items-center">
                <h6 className=" text-[16px] font-bold text-black">
                  Crypto Assets
                </h6>
                <span className="text-[12px] font-semibold text-[#CB212E]">
                  View All
                </span>
              </div>
              <div className="block  w-full text-left text-[16px] font-bold mt-[14px] bg-[#F0F0F0] px-2 py-1">
                <div className="inline-block w-[30%] box-border align-middle">
                  <img src="/Assets/WalletPage/Crypto2.png" alt="" />
                </div>
                <span className="inline-block w-[40%] box-border align-middle">
                  RST
                </span>
                <span className="inline-block w-[15%] box-border align-middle text-center">
                  {user?.balance &&
                    parseFloat(
                      ethers?.formatEther(BigInt(user?.balance))
                    ).toFixed(2)}
                </span>
                <IoIosArrowForward className="inline-block w-[15%] box-border align-middle font-bold text-right h-[22px]" />
              </div>
              <div className="block  w-full text-left text-[16px] font-bold mt-[14px] bg-[#F0F0F0] px-2 py-1">
                <div className="inline-block w-[30%] box-border align-middle">
                  <img src="/Assets/WalletPage/Crypto1.png" alt="" />
                </div>
                <div className="inline-block w-[40%] box-border align-middle">
                  <span className="block">BNB</span>
                  <span className="block text-[14px] text-semibold">
                    ${Bnbprice}
                  </span>
                </div>
                <span className="inline-block w-[15%] box-border align-middle text-center">
                  0
                </span>
                <IoIosArrowForward className="inline-block w-[15%] box-border align-middle font-bold text-right h-[22px]" />
              </div>
              <div className="block  w-full text-left text-[16px] font-bold mt-[14px] bg-[#F0F0F0] px-2 py-1">
                <div className="inline-block w-[30%] box-border align-middle">
                  <img src="/Assets/WalletPage/Crypto3.png" alt="" />
                </div>
                <div className="inline-block w-[40%] box-border align-middle">
                  <span className="block">USDT</span>
                  <span className="block text-[14px] text-semibold">
                    ${Usdtprice}
                  </span>
                </div>
                <div className="inline-block w-[15%] box-border align-middle text-center">
                  <span className="block">0</span>
                </div>

                <IoIosArrowForward className="inline-block w-[15%] box-border align-middle font-bold text-right h-[22px]" />
              </div>
              <div className="flex justify-between items-center">
                <h6 className=" text-[16px] font-bold mt-4 text-black">
                  History
                </h6>
                <span className="text-[12px] font-semibold text-[#CB212E]">
                  View All
                </span>
              </div>
            </div>
          </div>
        </div>
      </BurgerMenu>
    </>
  );
};

export default Wallet;

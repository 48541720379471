import { createContext, useState } from "react";

let userDataContext = createContext();

let UserDataContextWrapper = ({ children }) => {
	let [user, setUser] = useState();
	let [team, setTeam] = useState();
	let [rewards, setRewards] = useState();
	let [token, setToken] = useState();

	return (
		<userDataContext.Provider
			value={{
				user,
				setUser,
				team,
				setTeam,
				rewards,
				setRewards,
				token,
				setToken,
			}}
		>
			{children}
		</userDataContext.Provider>
	);
};

export { userDataContext };
export default UserDataContextWrapper;

import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "../config";
import { toast } from "react-toastify";
import BackArrow from "../Assets/BackArrow";

const DeleteAccount = () => {
	const navigate = useNavigate();
	const handleDeleteAccount = async () => {
		try {
			const token = localStorage.getItem("userToken");
			const DeleteAccountApiRes = await axios.post(
				SERVER_URL + "/users/delete-account",
				{},
				{
					headers: {
						authorization: token,
					},
				}
			);
			if (DeleteAccountApiRes.data.status == "success") {
				localStorage.removeItem("userToken");
				navigate("/");
			}
		} catch (error) {
			toast("Failed to delete account!");
		}
	};
	return (
		<>


			<div className='block bg-[#F4F4F2] h-[100%] px-[25px] relative z-20'>

				{/* <video src={BgVideo} type="video/mp4" autoPlay loop className="absolute z-[-10] w-full h-full object-fill top-0 left-0  overflow-scroll"></video> */}
				<div className='block pt-[50px]'>
					<div className='flex items-center justify-between relative'>
						<button onClick={() => navigate("/")} className='absolute left-0 top-[50%] translate-y-[-50%] ' ><BackArrow /></button>
						<h4 className='block text-center w-full text-[24px] font-bold text-first lg:border-b-2 lg:border-black lg:pb-4'>Delete Account</h4>
					</div>
					<div className="mt-5">
						<p className="text-center px-10">Are you sure you want to delete account?</p>
						<div className="flex justify-around items-center">

							<button onClick={() => navigate("/Profile")} className="cursor-pointer w-[40%] text-center mt-[21px] bg-[#CB202D] px-3 py-[5px] border-[1px] rounded-[8px] max-[320px]:text-[20px] max-[375px]:text-[22px] text-[18px] font-bold text-[#F4F4F2]">Yes</button>
							<button onClick={handleDeleteAccount} className="cursor-pointer w-[40%] text-center mt-[21px] bg-[#F4F4F2] px-3 py-[5px] border-[1px] border-[#CB202D] rounded-[8px] max-[320px]:text-[20px] max-[375px]:text-[22px] text-[18px] font-bold text-[#CB202D]">No</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DeleteAccount;

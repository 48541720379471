import React from "react";

function EllipseImg2({ ...props }) {
    return (
        <svg width="73" height="86" viewBox="0 0 73 86" fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
            <g opacity="0.3" filter="url(#filter0_d_1_472)">
                <circle cx="54" cy="28" r="50" fill="#D9D9D9" />
            </g>
            <defs>
                <filter id="filter0_d_1_472" x="0" y="-22" width="108" height="108" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_472" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_472" result="shape" />
                </filter>
            </defs>
        </svg>


    );
}

export default EllipseImg2;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Profileimg from "../Assets/Profilepage/ProfileImg";
import Arrow from "../Assets/Profilepage/Arrow";
import axios from "axios";
import { SERVER_URL } from "../config";
import BgVideo from "../Assets/bgvideo.mp4";
import BackArrow from "../Assets/BackArrow";
import { toast } from "react-toastify";
import { userDataContext } from "../context/UserDataContext";
const Profile = () => {
	const navigate = useNavigate();
	const { user } = useContext(userDataContext);

	return (
		<>
			<div className="block bg-[#F4F4F2] h-[100%] px-[25px] relative z-20 ">
				{/* <video src={BgVideo} type="video/mp4" autoPlay loop className="absolute z-[-10] w-full h-full object-fill top-0 left-0  overflow-scroll"></video> */}
				<div className="block pt-[50px]">
					<div className="flex items-center  relative">
						<button
							onClick={() => navigate("/")}
							className="absolute left-0 top-[50%] translate-y-[-50%] "
						>
							<BackArrow />
						</button>
						<h4 className="block text-center w-full text-[24px] font-bold text-first lg:border-b-2 lg:border-black lg:pb-4">
							My Profile
						</h4>
					</div>
					<div className="mx-6  lg:w-[50%] lg:mx-auto">
						<div className=" mt-[35px] ">
							{/* <Profileimg className="mx-auto " /> */}
							<div className="w-[106px] h-[106px] relative mx-auto flex justify-center items-center text-4xl bg-[#EAEAEA] rounded-lg">
								{user?.username && user?.username[0]}
							<span className="absolute bottom-[-10px] left-[85%] flex items-center justify-center text-[20px] w-[30px] h-[30px] rounded-[50%] border-2 border-[#C7202D]  bg-[#C7202D] text-[white]">
								+
							</span>
							</div>
						</div>
						<h6 className="block text-[20px] font-semibold text-[#C7202D] text-center mt-4">
							{user?.username}
						</h6>
						<p className="text-center mb-4">{user?.email}</p>
						<div className="block">
							<ul>
								<li
									onClick={() => navigate("/EmailChange")}
									className="flex items-center justify-between text-[14px] font-semibold text-[#40454A] py-[15px] border-b-2 px-2 border-[#C7CCD1]"
								>
									<span className="text-second">
										Email Change
									</span>
									<Arrow />
								</li>
								<li
									onClick={() => navigate("/PasswordChange")}
									className="flex items-center justify-between text-[14px] font-semibold text-[#40454A] py-[15px] border-b-2 px-2 border-[#C7CCD1]"
								>
									<span className="text-second">
										Password Change
									</span>
									<Arrow />
								</li>
								{/* <li
									onClick={() => toast("Coming Soon!")}
									className="flex items-center justify-between text-[14px] font-semibold text-[#40454A] py-[15px] border-b-2 px-2 border-[#C7CCD1]"
								>
									<span className="text-second">KYC</span>
									<Arrow />
								</li> */}
								<li
									onClick={() => navigate("/DeleteAccount")}
									className="flex items-center justify-between text-[14px] font-semibold text-[#40454A] py-[15px] border-b-2 px-2 border-[#C7CCD1]"
								>
									<span className="text-second">
										Delete Your Account
									</span>
									<Arrow />
								</li>
								<li
									onClick={() => toast("Coming Soon!")}
									className="flex items-center justify-between text-[14px] font-semibold text-[#40454A] py-[15px] border-b-2 px-2 border-[#C7CCD1]"
								>
									<span className="text-second">
										Language Select
									</span>
									<Arrow />
								</li>
								<li
									onClick={() => toast("Coming Soon!")}
									className="flex items-center justify-between text-[14px] font-semibold text-[#40454A] py-[15px] border-b-2 px-2 border-[#C7CCD1]"
								>
									<span className="text-second">
										Total Earning
									</span>
									<Arrow />
								</li>
								<li
									onClick={() => toast("Coming Soon!")}
									className="flex items-center justify-between text-[14px] font-semibold text-[#40454A] py-[15px] border-b-2 px-2 border-[#C7CCD1]"
								>
									<span className="text-second">
										Joining Date
									</span>
									<span className="text-second">
										{user &&
											new Date(
												user?.createdAt
											).toDateString()}
									</span>
								</li>
								<li
									onClick={() => {
										localStorage.removeItem("userToken");
										navigate("/login");
									}}
									className="flex items-center justify-between text-[14px] font-semibold text-[#40454A] py-[15px] border-b-2 px-2 border-[#C7CCD1]"
								>
									<span className="text-second">Logout</span>
									<Arrow />
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Profile;

import React from "react";

function EllipseImg1({ ...props }) {
    return (
        <svg width="103" height="56" viewBox="0 0 103 56" fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
        <g opacity="0.3" filter="url(#filter0_d_1_473)">
        <circle cx="54" cy="-2" r="50" fill="#D9D9D9"/>
        </g>
        <defs>
        <filter id="filter0_d_1_473" x="0" y="-52" width="108" height="108" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_473"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_473" result="shape"/>
        </filter>
        </defs>
        </svg>
        
    );
}

export default EllipseImg1;

import React, { useContext, useEffect, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { LuUser2, LuHome, LuUsers2, LuAlertCircle } from "react-icons/lu";
import { FiSettings } from "react-icons/fi";
import { IoClose, IoMenu } from "react-icons/io5";
import axios from "axios";
import { SERVER_URL } from "../config";
import { toast } from "react-toastify";
import AppIcon from "../Assets/icon.png";
import { userDataContext } from "../context/UserDataContext";
const BurgerMenu = (props) => {
	const navigate = useNavigate();
	const { user } = useContext(userDataContext);
	const [isOpen, setIsOpen] = useState(false);
	const [isUtilitiesVisible, setIsUtilitiesVisible] = useState(false);

	return (
		<div className="relative overflow-hidden  h-full md:flex-row flex flex-col">
			<div
				className={`${
					window.location.pathname === "/Wallet"
						? "bg-[#C7202D] "
						: "bg-[#F4F4F2]"
				} z-10  text-gray-100 w-full px-[10px] flex justify-between items-center md:hidden`}
			>
				{/* Mobile menu button */}
				<button
					className=" mobile-menu-button text-4xl  text-black h-[50px] delay-700	"
					onClick={() => setIsOpen(!isOpen)}
				>
					{isOpen ? "" : <IoMenu />}
				</button>
				<button
					onClick={() => navigate("/Profile")}
					className="flex items-center justify-center text-[20px] w-[40px] h-[40px] font-bold text-black  rounded-[50%] bg-[#B2DDFF]"
				>
					{user?.username[0]}
				</button>
			</div>

			<ClickAwayListener
				onClickAway={() => {
					setIsOpen(false);
				}}
			>
				<div
					className={`sidebar overflow-scroll z-50  lg:!-translate-x-0 h-full bg-[#F4F4F2] text-black w-64 top-0 py-7 pt-0 px-2 absolute lg:!relative  left-0 transform ${
						isOpen ? "-translate-x-0" : "-translate-x-full"
					} duration-200 ease-in-out  `}
				>
					<div className="flex justify-between items-center mt-8">
						<a
							href="#"
							className="text-black flex items-center space-x-2 px-4"
						>
							<img
								src={AppIcon}
								alt=""
								className="bg-[#F4F4F2] w-[50px] h-[50px]"
							/>
							<span className="text-2xl font-extrabold ">
								<span className="text-first">RED</span> SALE
							</span>
						</a>
						<button
							className="cursor-pointer ml-auto lg:hidden origin-center text-4xl text-black"
							onClick={() => setIsOpen(!isOpen)}
						>
							<IoClose />
						</button>
					</div>
					{/* Navigation */}
					<nav className="text-[20px] font-bold">
						<div
							onClick={() => navigate("/Profile")}
							className="cursor-pointer flex items-center focus:text-first  py-2.5 px-4 rounded transition duration-200 hover:text-[#962020] "
						>
							<LuUser2 className="mr-4" />
							My Profile
						</div>
						<div
							onClick={() => navigate("/")}
							className="cursor-pointer flex items-center focus:text-first  py-2.5 px-4 rounded transition duration-200 hover:text-[#962020] "
						>
							<LuHome className="mr-4" />
							Home
						</div>
						<div
							onClick={() => navigate("/MyTeam")}
							className="cursor-pointer flex items-center focus:text-first  py-2.5 px-4 rounded transition duration-200 hover:text-[#962020] "
						>
							<LuUsers2 className="mr-4" />
							Team
						</div>
						<div
							onClick={() => toast("Coming Soon!")}
							className="cursor-pointer flex items-center focus:text-first  py-2.5 px-4 rounded transition duration-200 hover:text-[#962020] "
						>
							<img
								src="/Assets/Vector.png"
								alt="img"
								className=" mr-4"
							/>
							Leader Board
						</div>
						<div
							onClick={() => navigate("/Faq")}
							className="cursor-pointer flex items-center focus:text-[#676FA3]  py-2.5 px-4 rounded transition duration-200 hover:text-first "
						>
							<LuAlertCircle className="mr-4" />
							FAQ
						</div>
						<div
							onClick={() => {
								setIsUtilitiesVisible(!isUtilitiesVisible);
							}}
							className="cursor-pointer flex items-center py-2.5 px-4 rounded transition duration-200 hover:text-first "
						>
							<FiSettings className="mr-4" />
							<div className="flex justify-between w-full">
								<span>Utilities </span>
								<span
									className={`${
										isUtilitiesVisible && "rotate-180 "
									}`}
								>
									▼
								</span>
							</div>
						</div>

						{isUtilitiesVisible && (
							<div className="pl-4">
								<div
									onClick={() => navigate("/Support")}
									className="cursor-pointer block py-2.5 px-4 rounded transition duration-200 hover:text-first "
								>
									Support
								</div>

								<div
									onClick={() => navigate("/Roadmap")}
									className="cursor-pointer block py-2.5 px-4 rounded transition duration-200 hover:text-first "
								>
									Road Map
								</div>
								<div
									onClick={() => toast("Coming Soon!")}
									className="cursor-pointer block py-2.5 px-4 rounded transition duration-200 hover:text-first "
								>
									White Paper
								</div>
								<div
									onClick={() => navigate("/RstTokenomics")}
									className="cursor-pointer block py-2.5 px-4 rounded transition duration-200 hover:text-first "
								>
									RST Tokenomics
								</div>
								<div
									onClick={() => navigate("/PrivacyPolicy")}
									className="cursor-pointer block py-2.5 px-4 rounded transition duration-200 hover:text-first "
								>
									Privacy Policy
								</div>
							</div>
						)}
						<div
							onClick={() => {
								localStorage.removeItem("userToken");
								navigate("/login");
							}}
							className="cursor-pointer py-2.5 px-4 mt-12 rounded transition duration-200 hover:text-first "
						>
							Log out
						</div>
					</nav>
				</div>
			</ClickAwayListener>

			<div className="w-full">
				<div
					className=" w-full "
					style={{ height: "calc(100% - 129px)" }}
				>
					<div
						className={`${
							window.location.pathname === "/Wallet" &&
							"!px-0 !py-0"
						}
							
						flex-1   h-full px-5 py-2 overflow-scroll `}
					>
						{props?.children}
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
};

export default BurgerMenu;

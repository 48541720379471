import React from "react";
import { useNavigate } from "react-router-dom";
import BackArrow from '../Assets/BackArrow';
import BgVideo from "../Assets/bgvideo.mp4"
const RstTokenomics = () => {
	const navigate = useNavigate();
	return (
		<>
			{/* <video src={BgVideo} type="video/mp4" autoPlay loop className="absolute z-[-10] w-full h-[100vh] object-fill top-0 left-0  "></video> */}
			<div className="block  h-[100vh] px-[25px] overflow-scroll ">
				<div className="block pt-[50px]">
					<div className="flex items-center  relative">
						<button
							onClick={() => navigate("/")}
							className="absolute left-0 top-[50%] translate-y-[-50%] "
						>
							<BackArrow />
						</button>
						<h4 className="block text-center w-full text-[24px] font-bold text-first lg:border-b-2 lg:border-black lg:pb-4">
							RST Tokenomics
						</h4>
					</div>
					<div className="block mt-[10px] ">
						<div className="text-[16px] font-semibold text-center">
							RedSale (RST ) Tokenomics Overview
						</div>
						<h6 className="block text-second text-[16px]  text-center font-semibold mt-2">
							Total supply:{" "}
							<p className="text-first"> 16,0000,000 RST </p>
							Token
						</h6>
						<div className="text-second mb-3">

							<div className="block mt-4">
								<p className="text-[12px] font-normal mb-2">
									The RedSale (RST) token is designed with a total
									supply of 160 million tokens. The distribution
									and allocation of these tokens are structured to
									support various facets of the project's
									ecosystem, ensuring sustainability, growth, and
									engagement across different sectors. Here is a
									detailed breakdown of the tokenomics:{" "}
								</p>
							</div>
							<div className="flex flex-col gap-8 lg:grid lg:grid-cols-2">
								<div>
									<p className="font-bold">
										{" "}
										1. Auto Burning (25%)
									</p>
									<p>
										{" "}
										<span className="font-bold">
											Allocation:
										</span>{" "}
										40,000,000 RST
									</p>
									<p>
										<span className="font-bold">Purpose:</span>{" "}
										To decrease the total supply over time,
										creating scarcity and potentially increasing
										the value of the remaining tokens. Auto
										burning is a mechanism where a portion of
										the tokens is periodically removed from
										circulation.
									</p>
								</div>
								<div>
									<p className="font-bold">2. Lock Token (25%)</p>
									<p>
										{" "}
										<span className="font-bold">
											Allocation:
										</span>
										40,000,000 RST
									</p>
									<p>
										{" "}
										<span className="font-bold">
											Lock Period:
										</span>{" "}
										Up to 2040
									</p>
									<p>
										{" "}
										<span className="font-bold">
											Purpose:
										</span>{" "}
										These tokens are locked for long-term
										stability and trust. Locking a significant
										portion of tokens ensures a committed future
										for the project and prevents large, sudden
										market impacts.
									</p>
								</div>
								<div>
									<p className="font-bold">
										3. Minting Program (15%)
									</p>
									<p>
										<span className="font-bold">
											Allocation:
										</span>
										24,000,000 RST
									</p>
									<p>
										{" "}
										<span className="font-bold">
											Purpose:
										</span>{" "}
										Incentivize network security and transaction
										validation. Tokens allocated for minting
										programs reward participants who contribute
										computational power to maintain and secure
										the blockchain.
									</p>
								</div>
								<div>
									<p className="font-bold">
										4. Exchange DEX & CEX (10%)
									</p>
									<p>
										<span className="font-bold">
											Allocation:
										</span>{" "}
										16,000,000 RST
									</p>
									<p>
										{" "}
										<span className="font-bold">
											Purpose:
										</span>{" "}
										Provide liquidity on decentralized (DEX) and
										centralized exchanges (CEX). This allocation
										ensures there are sufficient tokens
										available for trading and enhances market
										accessibility.
									</p>
								</div>
								<div>
									<p className="font-bold">
										5. Staking Program (5%)
									</p>
									<p>
										{" "}
										<span className="font-bold">
											Allocation:
										</span>
										8,000,000 RST
									</p>
									<p>
										{" "}
										<span className="font-bold">
											Purpose:
										</span>{" "}
										Reward holders who lock up their tokens to
										support network security and efficiency.
										Staking helps stabilize the token price and
										encourages long-term holding.
									</p>
								</div>
								<div>
									<p className="font-bold">
										6. Gaming Project (5%)
									</p>
									<p>
										{" "}
										<span className="font-bold">
											Allocation:
										</span>{" "}
										8,000,000 RST
									</p>
									<p>
										{" "}
										<span className="font-bold">
											Purpose:
										</span>{" "}
										Foster development and integration of
										blockchain in gaming. Tokens dedicated to
										gaming projects encourage innovation and
										engagement within the gaming community.
									</p>
								</div>
								<div>
									<p className="font-bold">
										7. Project Development & Marketing (5%)
									</p>
									<p>
										<span className="font-bold">
											Allocation:
										</span>{" "}
										8,000,000 RST
									</p>
									<p>
										{" "}
										<span className="font-bold">
											Purpose:
										</span>{" "}
										Support ongoing development and promotional
										activities. This fund ensures continuous
										improvement of the platform and effective
										marketing campaigns to attract users and
										investors.
									</p>
								</div>
								<div>
									<p className="font-bold">
										8. ICO & Airdrop (5%)
									</p>
									<p>
										{" "}
										<span className="font-bold">
											Allocation:
										</span>{" "}
										8,000,000 RST
									</p>
									<p>
										{" "}
										<span className="font-bold">Purpose:</span>
										Initial coin offering (ICO) and airdrop
										distribution to create initial liquidity and
										user base. This allocation is crucial for
										early-stage fundraising and building a
										community of token holders.
									</p>
								</div>
								<div>
									<p className="font-bold">
										9. Charity Fund, NGO, Etc. (5%)
									</p>
									<p>
										{" "}
										<span className="font-bold">
											Allocation:
										</span>{" "}
										8,000,000 RST
									</p>
									<p>
										{" "}
										<span className="font-bold">
											Purpose:
										</span>{" "}
										Support charitable activities and
										partnerships with non-governmental
										organizations (NGOs). This allocation
										emphasizes the project's commitment to
										social responsibility and community support.
									</p>
								</div>
								<div>
									<p className="text-2xl font-bold"> Summary </p>
									<p>
										The RedSale (RST) tokenomics are designed to
										balance the needs for growth, stability, and
										community engagement. By strategically
										allocating tokens across various programs
										and initiatives, RedSale aims to create a
										robust and sustainable ecosystem that
										benefits all stakeholders involved.
									</p>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</>
	);
};
export default RstTokenomics;

import React from "react";
import {
  LuAlertCircle,
  LuGem,
  LuHome,
  LuUsers2,
  LuWallet,
} from "react-icons/lu";
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <div className="  bg-[#F4F4F2] w-full border-t-[4px] h-[80px]  text-[14px]  fixed bottom-0 left-0 right-0  pb-3 z-40  ">
      <div className="flex justify-evenly items-center right-0  absolute lg:!w-[calc(100% - 256px)] w-full top-[-4px]">
        <div
          className={`border-t-[4px] ${
            window.location.pathname === "/Wallet"
              ? " text-first border-[#CB202D]"
              : "border-none"
          }`}
        >
          <NavLink
            to="/Wallet"
            className={`block font-semibold px-1 py-[12px] rounded-md `}
          >
            <LuWallet className="w-[30px] h-[30px] mx-[auto]" />
            {window.location.pathname === "/Wallet" && <span>Wallet</span>}
          </NavLink>
        </div>
        <div
          className={`border-t-[4px] ${
            window.location.pathname === "/"
              ? " text-first border-[#CB202D]"
              : "border-none"
          }  `}
        >
          <NavLink
            to="/"
            className={`block font-semibold px-1 py-[12px] rounded-md `}
          >
            <LuHome className="w-[30px] h-[30px] mx-[auto] " />
            {window.location.pathname === "/" && (
              <span className="mx-auto">Home</span>
            )}
          </NavLink>
        </div>
        <div
          className={`border-t-[4px] ${
            window.location.pathname === "/Premium"
              ? " text-first border-[#CB202D]"
              : "border-none"
          }`}
        >
          <NavLink
            to="/Premium"
            className={`block font-semibold px-1 py-[12px] rounded-md `}
          >
            <LuGem className="w-[30px] h-[30px] mx-[auto]" />
            {window.location.pathname === "/Premium" && <span>Minting</span>}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import { useNavigate } from "react-router-dom";
import BackArrow from '../Assets/BackArrow';
import BgVideo from "../Assets/bgvideo.mp4"


const Roadmap = () => {
	const navigate = useNavigate();
	return (
		<>
			{/* <video src={BgVideo} type="video/mp4" autoPlay loop className="absolute z-[-10] w-full h-[100vh] object-fill top-0 left-0  "></video> */}
			<div className="block  h-[100vh] px-[25px] relative z-20 overflow-scroll ">

				<div className="block pt-[50px] ">
					<div className="flex items-center relative gap-2">
						<button
							onClick={() => navigate("/")}
							className="absolute left-0 top-[50%] translate-y-[-50%] "
						>
							<BackArrow/>
						</button>
						<h4 className="block text-center w-full text-[24px] font-bold text-first lg:border-b-2 lg:border-black lg:pb-4">
							Road Map
						</h4>
					</div>
					<div className="mt-[10px] ml-[21px]  lg:grid  lg:grid-cols-2">
						<p className="uppercase text-[16px] font-semibold text-first">
							1. PHASE 1 - (2024)
							<p className="normal-case mt-2">
								<p className="font-normal text-[12px] text-second">
									❖ Project planning
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Website design
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Whitepaper
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Landing Page
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Smart Contract deployment
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Website pve{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Marketing push to spread awareness{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Minting app promotion{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Social media platforms{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Minting apppcation launching{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Pinksale psting and trending{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Concept and development{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Launch on pancake swipe{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Stacking program launching{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Pre-sale and Private sale rounds
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ 10+ Dex Exchange psting{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ 10+ Coin tracker psting{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Coingecko and coin market cap psting{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Project Audit & Team kyc{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ ICO psting (200+ website){" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ ICO lunching{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Small CEX psting on 1 or 2 Exchange
								</p>
							</p>
						</p>
						<p className="uppercase text-[16px] font-semibold text-first mt-[20px] lg:mt-0">
							2. PHASE 2 - (2025-2026)
							<p className="normal-case mt-2">
								<p className="font-normal text-[12px] text-second">
									❖ Project planning
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Website design
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Whitepaper
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Landing Page
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Smart Contract deployment
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Website pve{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Marketing push to spread awareness{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Minting app promotion{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Social media platforms{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Minting apppcation launching{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Pinksale psting and trending{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Concept and development{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Launch on pancake swipe{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Stacking program launching{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Pre-sale and Private sale rounds
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ 10+ Dex Exchange psting{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ 10+ Coin tracker psting{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Coingecko and coin market cap psting{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Project Audit & Team kyc{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ ICO psting (200+ website){" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ ICO lunching{" "}
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Small CEX psting on 1 or 2 Exchange
								</p>
							</p>
						</p>
						<p className="uppercase text-[16px] font-semibold text-first mt-[20px]">
							3. Phase 3 - (2026-2027)
							<p className="normal-case mt-2">
								<p className="font-normal text-[12px] text-second">
									❖ Listing 50 CEX exchange
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Gaming Platform Design
								</p>
								<p className="font-bold text-[12px] text-primary">
									Gaming phase -I
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Gaming websit testing and launching
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ 3D artist design for battle game
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Battle game demo video release
								</p>
								<p className="font-bold text-[12px] text-primary">
									Gaming phase - II
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Technical infrastructure construction &
									multiplayer server test
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Player self define black panther editor
									release
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Battle system development
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Create your requirement AND REDSALE Coin
									editor creation contest
								</p>
							</p>
						</p>
						<p className="uppercase text-[16px] font-semibold text-first mt-[20px]">
							4. Finely boosting stage - (2027-2028)
							<p className="normal-case mt-2 mb-2">
								<p className="font-normal text-[12px] text-second">
									❖ RSTCOIN battle system launching
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Listing 100 CEX exchange
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ RSTCOIN self Exchange
								</p>
								<p className="font-normal text-[12px] text-second">
									❖ Multiple Game launching
								</p>
							</p>
						</p>
					</div>
				</div>
			</div>
		</>
	);
};
export default Roadmap;

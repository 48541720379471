import React from 'react'
import Collapsible from './Collapse/Collapsible';
import { useNavigate } from 'react-router-dom';

import BackArrow from '../Assets/BackArrow';

const Faq = () => {
    const navigate = useNavigate();
    const sections = [
        {
            title: "1.What is RedSale (RST) ?",
            content: <li>RedSale is a gaming-focused cryptocurrency with a limited token supply designed to transform the gaming industry through innovative solutions and a robust ecosystem.</li>
        },
        {
            title: "2.What is the maximum tax fee for transactions?",
            content: <li>RedSale is a gaming-focused cryptocurrency with a limited token supply designed to transform the gaming industry through innovative solutions and a robust ecosystem.</li>
        },
        {
            title: "3.What sets RedSale apart from other crypto currencies?",
            content: <li>RedSale is a gaming-focused cryptocurrency with a limited token supply designed to transform the gaming industry through innovative solutions and a robust ecosystem.</li>
        },
        {
            title: "4.What makes RedSale unique? ",
            content: <li>RedSale is a gaming-focused cryptocurrency with a limited token supply designed to transform the gaming industry through innovative solutions and a robust ecosystem.</li>
        },
        {
            title: "5.How will RedSale achieve its projected value of $100 per token?",
            content: <li>RedSale is a gaming-focused cryptocurrency with a limited token supply designed to transform the gaming industry through innovative solutions and a robust ecosystem.</li>
        },
        {
            title: "6.What measures are in place to ensure the security of RedSale token holders?",
            content: <li>RedSale is a gaming-focused cryptocurrency with a limited token supply designed to transform the gaming industry through innovative solutions and a robust ecosystem.</li>
        },
        {
            title: "7.How can I get involved with RedSale?",
            content: <li>RedSale is a gaming-focused cryptocurrency with a limited token supply designed to transform the gaming industry through innovative solutions and a robust ecosystem.</li>
        },
        {
            title: "8.Is RedSale secure? ",
            content: <li>RedSale is a gaming-focused cryptocurrency with a limited token supply designed to transform the gaming industry through innovative solutions and a robust ecosystem.</li>
        }
        // Add more sections as needed
    ];
    return (
        <>
            <div className='block bg-[#F4F4F2] h-[100%]  px-[25px] relative z-20'>

                {/* <video src={BgVideo} type="video/mp4" autoPlay loop className="absolute z-[-10] w-full h-full object-fill top-0 left-0  overflow-scroll"></video> */}
                <div className='block pt-[50px]'>
                    <div className='flex items-center justify-between relative'>
                        <button onClick={() => navigate("/")} className='absolute left-0 top-[50%] translate-y-[-50%] ' ><BackArrow/></button>
                        <h4 className='block text-center w-full text-[24px] font-bold text-first lg:border-b-2 lg:border-black lg:pb-4'>FAQ</h4>
                    </div>
                    <div className='mt-[50px] text-second'>
                        <Collapsible sections={sections} />
                    </div>
                </div>
            </div>

        </>
    )
}

export default Faq
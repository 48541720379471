import React from 'react'

function Arrow({ ...props }) {
    return (
        <svg width="16" height="15" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M0.741797 1.76667L3.97513 5L0.741797 8.23333C0.416797 8.55833 0.416797 9.08333 0.741797 9.40833C1.0668 9.73333 1.5918 9.73333 1.9168 9.40833L5.7418 5.58333C6.0668 5.25833 6.0668 4.73333 5.7418 4.40833L1.9168 0.583335C1.5918 0.258335 1.0668 0.258335 0.741797 0.583335C0.42513 0.908335 0.416797 1.44167 0.741797 1.76667Z" fill="#000000" />
        </svg>

    )
}

export default Arrow

/* global BigInt */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "../config";
import { toast } from "react-toastify";
import { userDataContext } from "../context/UserDataContext";
import { ethers } from "ethers";

const MyTeam = () => {
	const [teamData, setTeamData] = useState([]);
	const navigate = useNavigate();

	const getTeam = async () => {
		const userToken = localStorage.getItem("userToken");
		const teamApiRes = await axios
			.post(
				`${SERVER_URL}/users/team`,
				{},
				{
					headers: {
						authorization: `Bearer ${userToken}`,
					},
				}
			)
			.catch((error) => {
				toast("Unable to fetch Team");
			});
		console.log(teamApiRes.data.data, "aaa");
		setTeamData(teamApiRes.data.data);
	};

	useEffect(() => {
		getTeam();
	}, []);

	return (
		<>
			<div className="block bg-[#F4F4F2] h-[100%] px-[25px]">
				<div className="block pt-[50px]">
					<div className="flex items-center  relative">
						<button
							onClick={() => navigate("/")}
							className="absolute left-0 top-[50%] translate-y-[-50%] "
						>
							<img src="/Assets/backarrow.png" alt="" />
						</button>
						<h4 className="block text-center w-full text-[24px] font-bold text-first lg:border-b-2 lg:border-black lg:pb-4 ">
							My Team
						</h4>
					</div>
					<div className="mt-[10px] ">
						<div className="flex justify-between w-full bg-[#EAEAEA] py-4 px-4 rounded-lg">
							<p className="text-sm w-1/3 text-center text-primary font-semibold">
								User Name
							</p>
							<p className="text-sm w-1/3 text-center text-primary font-semibold">
								Minting Status
							</p>
							<p className="text-sm w-1/3 text-center text-primary font-semibold">
								Balance
							</p>
						</div>
						<div className="flex flex-col w-full gap-4">
							{teamData?.map((data) => {
								return (
									<div className="flex justify-between px-4">
										<p className="w-1/3 text-center">
											{data.refereeId.username}
										</p>
										<p className="w-1/3 text-center">
											Active
										</p>
										<p className="w-1/3 text-center">
											{parseFloat(
												ethers?.formatEther(
													BigInt(
														data.refereeId.balance
													)
												)
											).toFixed(4)}
										</p>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default MyTeam;

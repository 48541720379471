export const SERVER_URL = "https://mining.redsale.app";

export const REWARD = {
	zero: 0.25,
	one: 1,
	two: 2,
	three: 5,
	four: 10,
};
export const REFFERAL_REWARD = {
	zero: 0.2,
	one: 0.2,
	two: 1,
	three: 2,
	four: 3,
};

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import MainScreen from "./components/MainScreen";
import { Login, Wallet } from "./components/index.js";
import Banner from "./components/Banner.js";
import Premium from "./components/Premium.js";
import Faq from "./components/Faq.js";
import RstTokenomics from "./components/RstTokenomics.js";
import Support from "./components/Support.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Roadmap from "./components/RoadMap.js";
import Profile from "./components/Profile.js";
import PrivacyPolicy from "./components/PrivacyPolicy.js";
import MyTeam from "./components/MyTeam.js";
import EmailChange from "./components/EmailChange.js";
import DeleteAccount from "./components/DeleteAccount.js";
import axios from "axios";
import { SERVER_URL } from "./config/index.js";
import { useContext, useEffect } from "react";
import { userDataContext } from "./context/UserDataContext.js";
import PasswordChange from "./components/PasswordChange.js";

function App() {
	
	return (
		<BrowserRouter>
			<div className=" h-screen w-full">
				<Routes>
					<Route path="/" Component={MainScreen} />
					<Route path="/Wallet" Component={Wallet} />
					<Route path="/Premium" Component={Premium} />
					<Route path="/login" Component={Login} />
					<Route path="/signup" Component={Login} />
					<Route path="/banner" Component={Banner} />
					<Route path="/Faq" Component={Faq} />
					<Route path="/RstTokenomics" Component={RstTokenomics} />
					<Route path="/Support" Component={Support} />
					<Route path="/Roadmap" Component={Roadmap} />
					<Route path="/MyTeam" Component={MyTeam} />
					<Route path="/Profile" Component={Profile} />
					<Route path="/EmailChange" Component={EmailChange} />
					<Route path="/PasswordChange" Component={PasswordChange} />
					<Route path="/DeleteAccount" Component={DeleteAccount} />

					<Route path="/PrivacyPolicy" Component={PrivacyPolicy} />
				</Routes>
			</div>
			<ToastContainer
				position="top-right"
				autoClose={1000}
				hideProgressBar={true}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
		</BrowserRouter>
	);
}

export default App;

import React from 'react'


const Fram2 = ({...props}) => {
    return (
        <>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M21.8525 10.0875C21.6536 10.0875 21.4628 10.0085 21.3222 9.86782C21.1815 9.72717 21.1025 9.53641 21.1025 9.33749V6.77624C21.1025 6.17254 20.8627 5.59357 20.4358 5.16669C20.0089 4.73981 19.43 4.49999 18.8263 4.49999H5.77627C5.17257 4.49999 4.59359 4.73981 4.16671 5.16669C3.73983 5.59357 3.50002 6.17254 3.50002 6.77624V9.33749C3.50002 9.53641 3.421 9.72717 3.28035 9.86782C3.13969 10.0085 2.94893 10.0875 2.75002 10.0875C2.5511 10.0875 2.36034 10.0085 2.21969 9.86782C2.07903 9.72717 2.00002 9.53641 2.00002 9.33749V6.77624C2.00101 5.77502 2.39918 4.8151 3.10715 4.10713C3.81512 3.39916 4.77505 3.00099 5.77627 2.99999H18.8263C19.8275 3.00099 20.7874 3.39916 21.4954 4.10713C22.2034 4.8151 22.6015 5.77502 22.6025 6.77624V9.33749C22.6025 9.53641 22.5235 9.72717 22.3828 9.86782C22.2422 10.0085 22.0514 10.0875 21.8525 10.0875Z" fill="#CB212E"/>
<path d="M11.7688 21.19C11.8385 21.2603 11.9214 21.3161 12.0128 21.3542C12.1042 21.3922 12.2023 21.4118 12.3013 21.4118C12.4003 21.4118 12.4983 21.3922 12.5897 21.3542C12.6811 21.3161 12.764 21.2603 12.8338 21.19L17.1013 16.9225C17.2203 16.7792 17.2818 16.5968 17.2737 16.4106C17.2657 16.2245 17.1886 16.0481 17.0576 15.9156C16.9266 15.7832 16.7509 15.7043 16.5649 15.6942C16.3789 15.6842 16.1958 15.7437 16.0513 15.8612L13.0513 18.8612V7.71249C13.0513 7.51358 12.9722 7.32282 12.8316 7.18216C12.6909 7.04151 12.5002 6.96249 12.3013 6.96249C12.1023 6.96249 11.9116 7.04151 11.7709 7.18216C11.6303 7.32282 11.5513 7.51358 11.5513 7.71249V18.85L8.55126 15.85C8.41053 15.7093 8.21966 15.6302 8.02063 15.6302C7.82161 15.6302 7.63074 15.7093 7.49001 15.85C7.34928 15.9907 7.27022 16.1816 7.27022 16.3806C7.27022 16.5796 7.34928 16.7705 7.49001 16.9112L11.7688 21.19Z" fill="#CB212E"/>
</svg>


        </>
    )
}
export default Fram2
/* global BigInt */
import axios from "axios";
import { ethers } from "ethers";
import React, { useContext, useEffect, useState } from "react";
import { REWARD, SERVER_URL } from "../config";
import Loader from "./Loader";

import { FaFacebookF, FaShareAltSquare, FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { IoLogoWhatsapp } from "react-icons/io";
import { toast } from "react-toastify";
import EllipseImg1 from "../Assets/HomePage/EllipseImg1";
import EllipseImg2 from "../Assets/HomePage/EllipseImg2";
import { userDataContext } from "../context/UserDataContext";
import { Link } from "react-router-dom";

const Home = () => {
	const { user, team, rewards, token } = useContext(userDataContext);

	const [isLoading, setIsLoading] = useState(false);
	const [isReferralVisible, setIsReferralVisible] = useState(false);
	const [lastMiningRecord, setLastMiningRecord] = useState("");

	const [rstTokenPerSrc, setRstTokenPerSrc] = useState(0);
	const initialCountdownTime = 3 * 60 * 60 * 1000;
	const [timer, setTimer] = useState({ hours: 0, minutes: 0, seconds: 0 });

	const socials = [
		{
			outlet: "LinkedIn",
			href: `https://www.linkedin.com/shareArticle?&text=I'm Minting the Red Sale token you can also do this by downloading the app. Use my refer code : ${user?.referralCode} \n https://play.google.com/store/apps/details?id=com.resale.io`,
			background: "#0a66c2",
			color: "white",
			label: "Share on LinkedIn",
			icon: <FaLinkedinIn className="h-5 w-5" />,
		},
		{
			outlet: "Whatsapp",
			href: `whatsapp://send?text=I'm Minting the Red Sale token you can also do this by downloading the app. Use my refer code : ${user?.referralCode} \n https://play.google.com/store/apps/details?id=com.resale.io`,
			background: "#7bcb20",
			color: "white",
			label: "Share via Whatsapp",
			icon: <IoLogoWhatsapp className="h-5 w-5" />,
		},
		{
			outlet: "Facebook",
			href: `https://www.facebook.com/sharer.php?u=I'm Minting the Red Sale token you can also do this by downloading the app. Use my refer code : ${user?.referralCode} \n https://play.google.com/store/apps/details?id=com.resale.io`,
			background: "#3b5898",
			color: "white",
			label: "Share on Facebook",
			icon: <FaFacebookF className="h-5 w-5" />,
		},
		{
			outlet: "Twitter",
			href: `https://twitter.com/intent/tweet?text=I'm Minting the Red Sale token you can also do this by downloading the app. Use my refer code : ${user?.referralCode} \n https://play.google.com/store/apps/details?id=com.resale.io`,
			background: "#00aced",
			color: "white",
			label: "Share on Twitter",
			icon: <FaTwitter className="h-5 w-5" />,
		},
		// {
		// 	outlet: "Email",
		// 	href: "mailto:?subject=Beginner's%20Guide%20to%20Jest%20Testing%20in%20React&body=https://dev.to/dsasse07/beginner-s-guide-to-jest-testing-in-react-1nig%20Check%20out%20this%20new%20article%20on%20Jest%20testing%20in%20React!",
		// 	background: "#dd4b39",
		// 	color: "white",
		// 	label: "Share via Email",
		// 	icon: <MdMail className="h-5 w-5" />,
		// },
		// {
		// 	outlet: "SMS",
		// 	href: "sms:?body=https://dev.to/dsasse07/beginner-s-guide-to-jest-testing-in-react-1nig",
		// 	background: "#7bcb20",
		// 	color: "white",
		// 	label: "Share via SMS",
		// 	icon: <MdSms className="h-5 w-5" />,
		// },
	];

	useEffect(() => {
		if (token !== undefined && token !== "") getLastMiningRecord();
	}, [token]);

	useEffect(() => {
		console.log(timer.seconds, "timer.seconds");
		if (timer.seconds !== 0) {
			const amount = rstTokenPerSrc + parseFloat(0.000018518518518518518);
			console.log("REDSALE amount", amount);
			setRstTokenPerSrc(amount === NaN ? 0 : amount);
		}
	}, [timer.seconds]);

	useEffect(() => {
		if (user) {
			const time = 10800 - getTimeRemaining() / 1000;
			const amount = time * parseFloat(0.000018518518518518518);
			setRstTokenPerSrc(amount === NaN ? 0 : amount);
		}
	}, [lastMiningRecord]);

	useEffect(() => {
		if (lastMiningRecord === null) {
			setTimer({ hours: 0, minutes: 0, seconds: 0 });
			return;
		}

		const timerInterval = setInterval(() => {
			const timeRemaining = getTimeRemaining();

			if (timeRemaining <= 0) {
				clearInterval(timerInterval);
				setTimer({ hours: 0, minutes: 0, seconds: 0 });
				return;
			}
			const hours = Math.floor(
				(timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			);
			const minutes = Math.floor(
				(timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
			);
			const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

			setTimer({ hours, minutes, seconds });
		}, 1000);

		return () => clearInterval(timerInterval);
	}, [lastMiningRecord]);

	function getTimeRemaining() {
		if (lastMiningRecord !== null) {
			const startTime = new Date(lastMiningRecord?.createdAt).getTime();
			const now = new Date().getTime();
			const elapsedTime = now - startTime;
			return initialCountdownTime - elapsedTime;
		} else {
			return 0;
		}
	}

	function getLastMiningRecord() {
		console.log(token, user);
		axios
			.get(`${SERVER_URL}/mining/last-mining`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (res) {
				setLastMiningRecord(res?.data?.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	function onClaim() {
		setIsLoading(true);
		axios
			.post(
				`${SERVER_URL}/mining/claim`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(function () {
				setRstTokenPerSrc(0);
				toast("Tokens claimed!", { type: "success" });
				setIsLoading(false);
			})
			.catch(function (error) {
				console.log(error.response.data.message);
				toast(error.response.data.message, { type: "error" });
				getLastMiningRecord();
				setIsLoading(false);
			});
	}

	return (
		<div className="flex  flex-col md:justify-around text-center items-center h-[100vh] lg:justify-normal lg:gap-3">
			{/* <video src={HomeBgVideo} type="video/mp4" autoPlay loop className="absolute z-[-10] w-full h-full object-fill top-0 left-0 bottom-0" style={{ height: "calc(100% - 76px)" }} ></video> */}

			{isReferralVisible && (
				<div
					className=" absolute z-20 rounded-lg  bg-white p-5"
					style={{
						boxShadow:
							"rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px",
					}}
				>
					<div className=" cursor-pointer w-full flex justify-between items-center">
						<span className="font-bold	"> Share </span>{" "}
						<div
							onClick={() => {
								setIsReferralVisible(false);
							}}
						>
							<ImCross className="h-3 w-3" />
						</div>
					</div>
					<div className="mt-4 flex justify-center items-center gap-4">
						{socials.map((data) => {
							return (
								<div className="flex flex-col gap-2 justify-center items-center">
									<a
										href={data.href}
										style={{
											boxShadow:
												"rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
										}}
										className="h-10 w-10 flex justify-center items-center bg-[#f5f5f5] rounded-full"
									>
										{data?.icon}
									</a>
									<span className="font-medium text-[#5d5959] text-xs">
										{data?.outlet}
									</span>
								</div>
							);
						})}
						<div className="flex flex-col gap-2 justify-center items-center">
							<button
								onClick={() => {
									navigator.share({
										title: "Download Red Sale App Now!",
										text: `I'm Minting the Red Sale token you can also do this by downloading the app. Use my refer code : ${user?.referralCode}`,
									});
								}}
								style={{
									boxShadow:
										"rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
								}}
								className="h-10 w-10 flex justify-center items-center bg-[#f5f5f5] rounded-full"
							>
								<FaShareAltSquare className="h-5 w-5" />
							</button>
							<span className="font-medium text-[#5d5959] text-xs">
								Share
							</span>
						</div>
					</div>
				</div>
			)}

			<div
				className="
			block w-full"
			>
				<div className=" w-[100%] mx-auto shadow-gray-200 p-2  rounded-md  box-border flex flex-col text-center  relative mt-4">
					<div className="absolute bg-[#CB212E] rounded-2xl w-full h-full top-0 left-0 overflow-hidden">
						<EllipseImg1 className="absolute w-full top-0 right-[-130px]" />
						<EllipseImg2 className="absolute w-full right-[-150px] top-[-5px]" />
					</div>
					<div className="z-10 p-2 ">
						<span className="text-[22px] text-[#ffffff] block  tracking-wide text-left font-bold">
							{user?.userType == "user"
								? rstTokenPerSrc.toFixed(4)
								: 0}{" "}
							RST
						</span>
						{/* <span className="text-2xl font-bold tracking-wide">00H:00M:00S</span> */}
						<div className="flex justify-between items-center mt-6">
							<button
								onClick={() => {
									if (!isLoading && user?.userType == "user") {
										onClaim();
									}
								}}
								className=" bg-red-400 px-[39px] py-[10px] mt-2 rounded-md font-bold text-[18px] text-third shadow-sm shadow-gray-900 border-2 border-white "
							>
								Claim{" "}
								{isLoading && (
									<Loader
										loaderHeight={"30px"}
										loaderWidth={"30px"}
									/>
								)}
							</button>
							<div>
								<div className=" flex text-[#BF2734] ">
									<span className="  text-third  text-[18px] font-[500] h-fit ">
										{timer?.hours
											.toString()
											.padStart(2, "0") == "NaN"
											? "0"
											: timer?.hours
													.toString()
													.padStart(2, "0")}
										:
									</span>

									<span className="  text-third  text-[18px] font-[500] h-fit ">
										{timer?.minutes
											.toString()
											.padStart(2, "0") == "NaN"
											? "0"
											: timer?.minutes
													.toString()
													.padStart(2, "0")}
										:
									</span>

									<span className="  text-third  text-[18px] font-[500] h-fit ">
										{timer?.seconds
											.toString()
											.padStart(2, "0") == "NaN"
											? "0"
											: timer?.seconds
													.toString()
													.padStart(2, "0")}
									</span>
								</div>
								<span className="block text-right text-[12px] font-[700] text-third">
									hours
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="flex justify-evenly w-full mt-[41px]">
				<div className="grid gap-2 w-full">
					<div className="flex justify-between">
						<div className="flex flex-col items-center bg-[#F0F0F0] border-[2px] p-2 rounded-md w-[32%] ">
							<span className="text-[16px] font-bold">
								{user
									? parseFloat(
											ethers?.formatEther(
												BigInt(user?.balance)
											)
									  ).toFixed(4)
									: 0}
							</span>
							<span className="text-[12px] text-[#828282] font-medium">
								RST
							</span>
						</div>
						<Link
							to="/MyTeam"
							className="cursor-pointer flex flex-col items-center bg-[#F0F0F0] border-[2px] p-2 rounded-md w-[32%] "
						>
							{/* <div > */}
							<span className="text-[16px] font-bold">
								{team?.length ? team?.length : 0}
							</span>
							<span className="text-[12px] text-[#828282] font-medium">
								MY TEAM
							</span>
							{/* </div> */}
						</Link>
						<div className="flex flex-col items-center bg-[#F0F0F0] border-[2px] p-2  rounded-md w-[32%]">
							<span className="text-[16px] font-bold">
								{rewards ? rewards?.rewards : 0} RST
							</span>
							<span className="text-[12px] text-[#828282] font-medium">
								Team Rewards
							</span>
						</div>
					</div>

					{/* <div className="flex flex-col items-center bg-[#00A478] shadow-3xl p-2 rounded-md  w-full">
						<span>{data?.directRewards?.toFixed(4)} RST</span>
						<span className="text-[18px] font-medium">
							Direct Rewards
						</span>
					</div> */}
					<div className="flex justify-between">
						<button
							onClick={() => {
								setIsReferralVisible(!isReferralVisible);
							}}
							// href="https://wa.me/?text=Download Red Sale App Now! and use my refferal code"
							className=" bg-[#F0F0F0] border-2  px-3 py-1 rounded-md font-medium  text-[16px] text-black w-[49%]"
						>
							Referral
						</button>

						<button
							onClick={() => toast("Coming Soon!")}
							className="border-2 bg-[#CB212E] px-3 py-1 rounded-md font-medium text-[16px] text-third  w-[49%]"
						>
							Staking
						</button>
					</div>
				</div>
			</div>

			{/* <div className="flex justify-between w-full mt-[31px]">
				<div className="flex-col" onClick={() => toast("Coming Soon!")}>
					<div className="w-[50px] h-[50px] rounded-[50%] flex justify-center items-center bg-[#53B1FD]">
						<img src={launchpadPng} alt="" className=" w-[20px]" />
					</div>
					<span className="text-[10px] font-medium text-third">Launchpad</span>
				</div>
				<div className="flex-col" onClick={() => toast("Coming Soon!")}>
					<div className="w-[50px] h-[50px] rounded-[50%] flex justify-center items-center bg-[#D0D5DD]">
						<img src={kycPng} alt="" className=" w-[20px]" />
					</div>
					<span className="text-[10px] font-medium text-third">Kyc</span>
				</div>
				<div className="flex-col" onClick={() => toast("Coming Soon!")}>
					<div className="w-[50px] h-[50px] rounded-[50%] flex justify-center items-center bg-[#C7D7FE]">
						<img src={premiumPng} alt="" className="w-[20px]" />
					</div>
					<span className="text-[10px] font-medium text-third">Premium</span>
				</div>
				<div className="flex-col" onClick={() => toast("Coming Soon!")}>
					<div className="w-[50px] h-[50px] rounded-[50%] flex justify-center items-center bg-[#F8A408]">
						<img src={referencePng} alt="" className=" w-[20px]" />
					</div>
					<span className="text-[10px] font-medium text-third">Reference</span>
				</div>
				<div className="flex-col" onClick={() => toast("Coming Soon!")}>
					<div className="w-[50px] h-[50px] rounded-[50%] flex justify-center items-center bg-[#F9E795]">
						<img src={gamePng} alt="" className=" w-[20px]" />
					</div>
					<span className="text-[10px] font-medium text-third">Game</span>
				</div>
			</div> */}

			<div className="block w-full mt-[20px]">
				<h6 className="text-[20px] font-bold block text-left text-black">
					News
				</h6>
				<div
					className="block mt-[17px] border-[1px] rounded-[6px] border-white"
					onClick={() => toast("Coming Soon!")}
				>
					<p className=" text-[14px] font-semibold  tracking-wide text-left text-black font-Newsreader pl-4 pt-2 pr-4">
						5 things to know about the 'conundrum' of lupus
					</p>
					<div className="flex justify-between mt-8 mb-2">
						<span className=" font-Nunito inlin text-black text-[12px]  pl-4 ">
							Matt Villano
						</span>
						<span className="  font-Nunito text-black text-[12px] pr-4 ">
							Sunday, 9 May 2021
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;

import axios from "axios";
import React, { useContext, useState } from "react";
import { SERVER_URL } from "../config";
import { toast } from "react-toastify";
import BackArrow from "../Assets/BackArrow";
import { useNavigate } from "react-router-dom";
import { userDataContext } from "../context/UserDataContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const PasswordChange = () => {
	const { token } = useContext(userDataContext);
	const navigate = useNavigate();
	const [otp, setOtp] = useState("");
	const [email, setEmail] = useState("");
	const [isOTPSending, setIsOTPSending] = useState(false);

	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
		useState(false);

	const [stage, setStage] = useState(0);

	const handleGenerateOTP = async () => {
		setIsOTPSending(true);
		console.log(token);
		try {
			const generateOtpApiRes = await axios.post(
				SERVER_URL +
					(token
						? "/users/update-password"
						: "/users/forgot-pass-otp"),
				{ email },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (generateOtpApiRes.data.status == "success") {
				toast("otp sent Successfully!");
				setStage(1);
				setIsOTPSending(false);
			}
		} catch (error) {
			console.log(error);
			setIsOTPSending(false);
			toast("Failed to Send otp!");
		}
	};

	const handleVerifyOTP = async () => {
		try {
			const verifyOtpApiRes = await axios.post(
				SERVER_URL + "/users/verify-otp",
				{
					otp,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (verifyOtpApiRes.data.status == "success") {
				toast("OTP Verified!");
				setStage(2);
			}
		} catch (error) {
			console.log(error);
			if (error?.response) {
				toast(error?.response?.data?.message, { type: "error" });
			} else {
				toast("Failed to verify otp!");
			}
		}
	};

	const handleChangePassword = async () => {
		try {
			const changePasswordApiRes = await axios.post(
				SERVER_URL +
					(token
						? "/users/change-password"
						: "/users/forgot-pass-change"),
				{
					password,
					email,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (changePasswordApiRes.data.status == "success") {
				toast("Password changed successfully!");
				setStage(3);
			}
		} catch (error) {
			console.log(error);
			if (error?.response) {
				toast(error?.response?.data?.message, { type: "error" });
			} else {
				toast("Failed to verify otp!");
			}
		}
	};
	return (
		<>
			<div className="block bg-[#F4F4F2] h-[100%] px-[25px] relative z-20">
				{/* <video src={BgVideo} type="video/mp4" autoPlay loop className="absolute z-[-10] w-full h-full object-fill top-0 left-0  overflow-scroll"></video> */}
				<div className="block pt-[50px]">
					<div className="flex items-center justify-between relative">
						<button
							onClick={() => navigate("/")}
							className="absolute left-0 top-[50%] translate-y-[-50%] "
						>
							<BackArrow />
						</button>
						<h4 className="block text-center  w-full text-[24px] font-bold text-first lg:border-b-2 lg:border-black lg:pb-4">
							Password Change
						</h4>
					</div>

					{stage == 0 && (
						<div className="lg:w-[50%] lg:mx-auto">
							{!token && (
								<>
									{" "}
									<label className="text-[16px] max-[320px]:text-[14px]  font-normal text-gray-600 mt-[21px] block ">
										Enter Email
									</label>
									<input
										className="text-start block w-full max-[320px]:p-3 p-4 mt-[7px] border-[1px] outline-none border-[#98A2B3] rounded-[8px] max-[320px]:text-[12px] text-[14px] bg-[#f3f4f6] "
										type="text"
										name="email"
										onChange={(e) =>
											setEmail(e.target.value)
										}
									/>
								</>
							)}
							<label className="text-[16px] max-[320px]:text-[14px]  font-normal text-gray-600 mt-[21px] block  ">
								Click below to generate otp for password change
							</label>
							<button
								onClick={handleGenerateOTP}
								className="cursor-pointer w-full text-center mt-[21px] bg-[#CB202D] px-3 py-[5px] border-[1px] rounded-[8px] max-[320px]:text-[20px] max-[375px]:text-[22px] text-[24px] font-bold text-[#F4F4F2]"
							>
								{isOTPSending ? "Sending OTP" : "Generate OTP"}
							</button>
						</div>
					)}

					{stage == 1 && (
						<div>
							<label className="text-[16px] max-[320px]:text-[14px]  font-normal text-gray-600 mt-[21px] block ">
								Enter OTP
							</label>
							<input
								className="text-center block w-full max-[320px]:p-3 p-4 mt-[7px] border-[1px] outline-none border-[#98A2B3] rounded-[8px] max-[320px]:text-[12px] text-[14px] bg-[#f3f4f6] "
								type="text"
								name="email"
								onChange={(e) => setOtp(e.target.value)}
							/>
							<button
								onClick={handleVerifyOTP}
								className="cursor-pointer w-full text-center mt-[21px] bg-[#CB202D] px-3 py-[5px] border-[1px] rounded-[8px] max-[320px]:text-[20px] max-[375px]:text-[22px] text-[24px] font-bold text-[#F4F4F2]"
							>
								Verify OTP
							</button>
						</div>
					)}

					{stage == 2 && (
						<div>
							<label className="text-[16px] max-[320px]:text-[14px]  font-normal text-gray-600 mt-[21px] block ">
								Password
							</label>
							<div className=" flex  overflow-hidden w-full bg-[#f3f4f6] mt-2 border-[1px] border-[#98A2B3] rounded-[8px]">
								<input
									className=" p-4 pr-0 max-[320px]:p-3 text-[14px] max-[320px]:text-[12px] outline-none w-full bg-[#f3f4f6] "
									type={
										isPasswordVisible ? "text" : "password"
									}
									name="password"
									placeholder="Enter your password"
									value={password}
									onChange={(e) =>
										setPassword(e.target.value)
									}
								/>
								<button
									className="px-4"
									onClick={() => {
										setIsPasswordVisible(
											!isPasswordVisible
										);
									}}
								>
									{isPasswordVisible ? (
										<FaEyeSlash />
									) : (
										<FaEye />
									)}
								</button>
							</div>
							<label className="text-[16px] max-[320px]:text-[14px]  font-normal text-gray-600 mt-[21px] block ">
								Confirm Password
							</label>
							<div className=" flex  overflow-hidden w-full bg-[#f3f4f6] mt-2 border-[1px] border-[#98A2B3] rounded-[8px]">
								<input
									className=" p-4 pr-0 max-[320px]:p-3 text-[14px] max-[320px]:text-[12px] outline-none w-full bg-[#f3f4f6] "
									type={
										isConfirmPasswordVisible
											? "text"
											: "password"
									}
									name="password"
									placeholder="Enter your password"
									value={confirmPassword}
									onChange={(e) =>
										setConfirmPassword(e.target.value)
									}
								/>
								<button
									className="px-4"
									onClick={() => {
										setIsConfirmPasswordVisible(
											!isConfirmPasswordVisible
										);
									}}
								>
									{isConfirmPasswordVisible ? (
										<FaEyeSlash />
									) : (
										<FaEye />
									)}
								</button>
							</div>
							<button
								onClick={handleChangePassword}
								className="cursor-pointer w-full text-center mt-[21px] bg-[#CB202D] px-3 py-[5px] border-[1px] rounded-[8px] max-[320px]:text-[20px] max-[375px]:text-[22px] text-[24px] font-bold text-[#F4F4F2]"
							>
								Change Password
							</button>
						</div>
					)}
					{stage == 3 && (
						<div>
							<h4 className="block text-center w-full text-[30px] font-bold ">
								Password Changed Successfully!
							</h4>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default PasswordChange;

import axios from "axios";
import React, { useState } from "react";
import { SERVER_URL } from "../config";
import { toast } from "react-toastify";
import BackArrow from '../Assets/BackArrow';
import { useNavigate } from 'react-router-dom';


const EmailChange = () => {
	const navigate = useNavigate();

	const [email, setEmail] = useState("");
	const handleUpdateEmail = async () => {
		try {
			const token = localStorage.getItem("userToken");
			const UpdateEmailApiRes = await axios.post(
				SERVER_URL + "/users/update-email",
				{
					email: email,
				},
				{
					headers: {
						authorization: token,
					},
				}
			);
			if (UpdateEmailApiRes.data.status == "success") {
				toast("Successfully updated Email!");

				localStorage.setItem("userToken", UpdateEmailApiRes.data.token);
			}
		} catch (error) {
			console.log(error);
			toast("Failed to updated Email!");
		}
	};
	return (
		<>

			<div className='block bg-[#F4F4F2] h-[100%] px-[25px] relative z-20'>

				{/* <video src={BgVideo} type="video/mp4" autoPlay loop className="absolute z-[-10] w-full h-full object-fill top-0 left-0  overflow-scroll"></video> */}
				<div className='block pt-[50px]'>
					<div className='flex items-center justify-between relative'>
						<button onClick={() => navigate("/")} className='absolute left-0 top-[50%] translate-y-[-50%] ' ><BackArrow /></button>
						<h4 className='block text-center w-full text-[24px] font-bold text-first lg:border-b-2 lg:border-black lg:pb-4'>Email Change</h4>
					</div>
					<div className="lg:w-[50%] lg:mx-auto">

						<label

							className="text-[16px] max-[320px]:text-[14px]  font-normal text-gray-600 mt-[21px] block "
						>
							Enter New Email<span className="text-red-500">*</span>
						</label>
						<input
							className=" block w-full max-[320px]:p-3 p-4 mt-[7px] border-[1px] outline-none border-[#98A2B3] rounded-[8px] max-[320px]:text-[12px] text-[14px] bg-[#f3f4f6] "
							type="email"
							name="email"

							onChange={(e) => setEmail(e.target.value)}
						/>
						<button onClick={handleUpdateEmail} 	className="cursor-pointer w-full text-center mt-[21px] bg-[#CB202D] px-3 py-[5px] border-[1px] rounded-[8px] max-[320px]:text-[20px] max-[375px]:text-[22px] text-[24px] font-bold text-[#F4F4F2]" >Update Email</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default EmailChange;

import React from 'react'


const Fram4 = ({ ...props }) => {
    return (
        <>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path d="M12.1245 2C6.54176 2 1.99997 6.54179 1.99997 12.1245C1.99997 15.8928 4.07719 19.3277 7.42093 21.0888C7.7109 21.2415 8.06965 21.1302 8.22243 20.8404C8.37508 20.5504 8.26383 20.1917 7.97386 20.0391C5.02083 18.4837 3.18643 15.4511 3.18643 12.1245C3.18643 7.19605 7.19601 3.18647 12.1245 3.18647C17.0529 3.18647 21.0625 7.19605 21.0625 12.1245C21.0625 15.5053 19.1846 18.5604 16.1615 20.0977C15.8695 20.2462 15.7532 20.6034 15.9017 20.8954C16.0064 21.1013 16.2149 21.2198 16.431 21.2198C16.5215 21.2198 16.6133 21.1991 16.6995 21.1552C18.341 20.3205 19.7271 19.0531 20.7075 17.4902C21.716 15.8823 22.249 14.027 22.249 12.1245C22.249 6.54179 17.7072 2 12.1245 2Z" fill="#CB212E" />
                <path d="M18.0783 15.8682C18.7854 14.7474 19.1591 13.4528 19.1591 12.1245C19.1591 8.24564 16.0034 5.08975 12.1245 5.08975C8.24545 5.08975 5.08972 8.24564 5.08972 12.1245C5.08972 13.4528 5.46346 14.7472 6.17051 15.8682C6.85799 16.9577 7.82864 17.8385 8.9777 18.4156C9.27064 18.5625 9.62717 18.4443 9.77409 18.1516C9.92102 17.8586 9.80296 17.5021 9.51011 17.3552C7.51534 16.3536 6.27619 14.3495 6.27619 12.1245C6.27619 8.8997 8.89971 6.27622 12.1245 6.27622C15.3491 6.27622 17.9726 8.89974 17.9726 12.1245C17.9726 14.3495 16.7335 16.3538 14.7387 17.3552C14.4458 17.5023 14.3276 17.8586 14.4747 18.1516C14.5789 18.3589 14.788 18.4788 15.0054 18.4788C15.0977 18.4787 15.1887 18.4571 15.2711 18.4156C16.4212 17.8377 17.3918 16.957 18.0783 15.8682Z" fill="#CB212E" />
                <path d="M16.0694 12.1245C16.0694 9.94931 14.2997 8.17966 12.1245 8.17966C9.94931 8.17966 8.17966 9.94931 8.17966 12.1245C8.17966 13.6255 9.01513 14.9774 10.3599 15.6524C10.4423 15.6939 10.5333 15.7155 10.6255 15.7156C10.8429 15.7156 11.0521 15.5957 11.1562 15.3884C11.3031 15.0955 11.1849 14.739 10.8922 14.592C9.95089 14.1195 9.36612 13.1741 9.36612 12.1245C9.36612 10.6036 10.6036 9.36612 12.1245 9.36612C13.6454 9.36612 14.8829 10.6036 14.8829 12.1245C14.8829 13.1739 14.2983 14.1194 13.357 14.5918C13.0643 14.7389 12.9461 15.0953 13.093 15.3882C13.2401 15.681 13.5966 15.7992 13.8894 15.6522C15.234 14.9771 16.0694 13.6253 16.0694 12.1245Z" fill="#CB212E" />
            </svg>


        </>
    )
}
export default Fram4
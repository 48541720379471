// Loader.js
import React from "react";

const Loader = ({loaderHeight,loaderWidth}) => {
    return (
            <div className="spinner" style={{height:loaderHeight , width:loaderWidth}}></div>
    );
};

export default Loader;

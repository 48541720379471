import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { isValidEmail } from "../utils";
import axios from "axios";
import { SERVER_URL } from "../config";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import BgVideo from "../Assets/bgvideo.mp4";

const Login = () => {
	const navigate = useNavigate();
	const [pass, setPass] = useState("");
	const [userName, setuserName] = useState("");
	const [email, setEmail] = useState("");
	const [refferalCode, setRefferalCode] = useState("");
	const [emailError, setEmailErorr] = useState(false);
	const [passError, setPassErorr] = useState(false);
	const [confirmPass, setConfirmPass] = useState("");
	const [action, setAction] = useState("Login");
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
		useState(false);

	const signUp = () => {
		if (!isValidEmail(email)) {
			setEmailErorr(true);
			return;
		}
		if (pass === "" || confirmPass === "" || pass !== confirmPass) {
			setPassErorr(true);
			return;
		}
		if (refferalCode == "") {
			toast("Please Provide Refferal Code");
			return;
		}
		const paylod = {
			username: userName,
			email,
			phone: "",
			password: pass,
			refferalCode: refferalCode,
		};
		axios
			.post(`${SERVER_URL}/users/register`, paylod)
			.then(function () {
				toast("Registed successfully!", { type: "success" });
				setPass("");
				setuserName("");
				setEmail("");
				setConfirmPass("");
				setAction("Login");
			})
			.catch(function (error) {
				toast("Unable to create account!", { type: "error" });
				console.log(error);
			});
	};

	const login = () => {
		if (!isValidEmail(email)) {
			setEmailErorr(true);
			return;
		}

		const paylod = {
			email,
			password: pass,
		};

		axios
			.post(`${SERVER_URL}/users/login`, paylod)
			.then(function (res) {
				toast("Welcome back!", { type: "success" });
				setPass("");
				setEmail("");
				localStorage.setItem("userToken", res?.data?.token);
				navigate("/");
			})
			.catch((e) => {
				console.log(e.response.data.message);
				toast(
					"Unable to Login! \n Please check your email and password"
				);
			});
	};

	useEffect(() => {
		if (emailError || passError) {
			setTimeout(() => {
				setEmailErorr(false);
				setPassErorr(false);
			}, [3000]);
		}
	}, [emailError, passError]);

	useEffect(() => {
		if (window.location.pathname == "/signup") {
			setAction("Sign up");
		}
	}, []);
	function errorText(text) {
		return (
			<div className="text-xs text-red-700 text-red font-medium">
				{text}
			</div>
		);
	}

	return (
		<div className="h-full w-full">
			{/* <video src={BgVideo} width="320" height="240" type="video/mp4" autoPlay className="absolute z-[-10] w-full h-full object-fill"></video> */}

			<div className=" mx-auto  max-[320px]:max-w-[290px] py-5 px-3  max-[375px]:max-w-[300px]  max-w-[354px]">
				<h1 className="text-[32px]  max-[320px]:text-[22px] max-[375px]:text-[26px] font-bold">
					{action === "Login" ? (
						<p className="pt-[40px] text-second">
							Wellcome <span className="text-first">back</span>
						</p>
					) : (
						action
					)}{" "}
				</h1>
				<div className="block">
					{action === "Sign up" && (
						<>
							{" "}
							<label
								htmlFor="username"
								className="text-[16px] max-[320px]:text-[14px] font-normal text-gray-600"
							>
								Full name<span className="text-red-500">*</span>
							</label>
							<input
								type="text"
								name="username"
								placeholder="Full name"
								value={userName}
								onChange={(e) => setuserName(e.target.value)}
								className="block w-full p-4 max-[320px]:p-3 max-[320px]:text-[12px] mt-[7px] border-[1px] border-[#98A2B3] rounded-[8px] text-[14px] outline-none bg-[#f3f4f6]"
							/>
						</>
					)}
					{action === "Sign up" && (
						<>
							{" "}
							<label
								htmlFor="username"
								className="text-[16px] max-[320px]:text-[14px]  font-normal text-gray-600 mt-[21px] block"
							>
								Refferal Code
								<span className="text-red-500">*</span>
							</label>
							<input
								type="text"
								name="refferalCode"
								placeholder="REFFERAL"
								value={refferalCode}
								onChange={(e) =>
									setRefferalCode(e.target.value)
								}
								className="block w-full p-4 max-[320px]:p-3 max-[320px]:text-[12px] mt-[7px] border-[1px] border-[#98A2B3] rounded-[8px] text-[14px] outline-none bg-[#f3f4f6]"
							/>
						</>
					)}

					<label
						htmlFor="username"
						className="text-[16px] max-[320px]:text-[14px]  font-normal text-gray-600 mt-[21px] block "
					>
						Email<span className="text-red-500">*</span>
					</label>
					<input
						type="text"
						name="username"
						placeholder="Enter your email or phone number"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className="block w-full max-[320px]:p-3 p-4 mt-[7px] border-[1px] outline-none border-[#98A2B3] rounded-[8px] max-[320px]:text-[12px] text-[14px] bg-[#f3f4f6]"
					/>
					{emailError && errorText("email is not  valid")}
					<label
						htmlFor="confirm-pass"
						className="text-[16px] max-[320px]:text-[14px] font-normal text-gray-600 mt-[21px] block "
					>
						Password<span className="text-red-500">*</span>
					</label>
					<div className=" flex  overflow-hidden w-full bg-[#f3f4f6] mt-2 border-[1px] border-[#98A2B3] rounded-[8px]">
						<input
							className=" p-4 pr-0 max-[320px]:p-3 text-[14px] max-[320px]:text-[12px] outline-none w-full bg-[#f3f4f6] "
							type={isPasswordVisible ? "text" : "password"}
							name="password"
							placeholder="Enter your password"
							value={pass}
							onChange={(e) => setPass(e.target.value)}
						/>
						<button
							className="px-4"
							onClick={() => {
								setIsPasswordVisible(!isPasswordVisible);
							}}
						>
							{isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
						</button>
					</div>
					{action === "Login" && (
						<>
							<div className="flex justify-between items-center mt-[7px] max-[320px]:text-[12px] max-[375px]:text-[14px] text-[16px]">
								<div className="inline-block">
									<input
										type="checkbox"
										name="Remember"
										className="mr-2 w-[20px] h-[20px] max-[320px]:h-[16px] max-[375px]:h-[18px]  max-[320px]:w-[16px] max-[375px]:w-[18px] bg-[#CB202D]"
										style={{ accentColor: "#CB202D" }}
									/>
									<label
										htmlFor="Remember"
										className="font-bold  text-second"
									>
										Remember me
									</label>
								</div>
								<Link
									className="text-first "
									to={"/PasswordChange"}
								>
									Forget Password?
								</Link>
							</div>
						</>
					)}

					{action === "Sign up" && pass !== "" && (
						<div>
							<label
								htmlFor="confirm-pass"
								className="text-[16px] max-[320px]:text-[14px] font-normal  text-gray-600 mt-[21px] block"
							>
								Confirm-password{" "}
								<span className="text-red-500">*</span>
							</label>
							<div className=" flex rounded-[8px] overflow-hidden w-full bg-white mt-[7px] border-[1px] border-[#98A2B3]">
								<input
									className=" p-4 pr-0 text-[14px] max-[320px]:p-3 max-[320px]:text-[12px] outline-none w-full bg-[#f3f4f6]"
									type={
										isConfirmPasswordVisible
											? "text"
											: "password"
									}
									name="password"
									placeholder="Enter your password"
									value={confirmPass}
									onChange={(e) =>
										setConfirmPass(e.target.value)
									}
								/>
								<button
									className="px-4 bg-[#f3f4f6]"
									onClick={() => {
										setIsConfirmPasswordVisible(
											!isConfirmPasswordVisible
										);
									}}
								>
									{isConfirmPasswordVisible ? (
										<FaEyeSlash />
									) : (
										<FaEye />
									)}
								</button>
							</div>
						</div>
					)}
					{passError &&
						errorText(`password is empty or password did't match`)}

					<button
						onClick={() => {
							if (action === "Sign up") {
								signUp();
							} else {
								login();
							}
						}}
						className="cursor-pointer w-full text-center mt-[21px] bg-[#CB202D] px-3 py-[5px] border-[1px] rounded-[8px] max-[320px]:text-[20px] max-[375px]:text-[22px] text-[24px] font-bold text-[#F4F4F2]"
					>
						{action}
					</button>

					<p
						onClick={() => {
							setAction(
								action === "Sign up" ? "Login" : "Sign up"
							);
						}}
						className=" max-[320px]:text-[12px] max-[375px]:text-[14px] text-[16px] text-normal leading-[24px] tracking-wide mt-1 text-second"
					>
						You have an account?{" "}
						<button className="cursor-pointer text-red-600">
							{action === "Sign up" ? "Sign in now!" : "Sign up"}
						</button>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Login;

import React, { useState } from 'react';
import { IoIosArrowForward } from "react-icons/io";
function Collapsible({ sections }) {
    const [collapsedSections, setCollapsedSections] = useState(
        new Array(sections.length).fill(true)
    );

    const toggleCollapse = (index) => {
        const updatedCollapsedSections = [...collapsedSections];
        updatedCollapsedSections[index] = !collapsedSections[index];
        setCollapsedSections(updatedCollapsedSections);
    };

    return (
        <div>
            {sections.map((section, index) => (
                <div key={index} >
                    <div
                        className="  py-2 cursor-pointer"
                        onClick={() => toggleCollapse(index)}
                    >
                        <div className='text-[14px] flex justify-between items-start  font-semibold '>
                            <h3 className='w-[95%]'>

                                {section.title}
                            </h3>
                            <IoIosArrowForward
                                className={`h-[20px] w-[20px]  transform ${collapsedSections[index] ? 'rotate-[90deg]' : 'rotate-[270deg]'
                                    }`}

                            />
                        </div>

                    </div>
                    {!collapsedSections[index] && <div className="px-3 text-[12px] font-normal ">{section.content}</div>}
                </div>
            ))}
        </div>
    );
}

export default Collapsible;

import React from 'react'


const Fram1 = ({...props}) => {
    return (
        <>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path d="M22.0513 14.475C21.8523 14.475 21.6616 14.554 21.5209 14.6947C21.3803 14.8353 21.3013 15.0261 21.3013 15.225V17.7863C21.3013 18.39 21.0614 18.9689 20.6346 19.3958C20.2077 19.8227 19.6287 20.0625 19.025 20.0625H5.975C5.3713 20.0625 4.79233 19.8227 4.36545 19.3958C3.93857 18.9689 3.69875 18.39 3.69875 17.7863V15.225C3.69875 15.0261 3.61973 14.8353 3.47908 14.6947C3.33843 14.554 3.14766 14.475 2.94875 14.475C2.74984 14.475 2.55907 14.554 2.41842 14.6947C2.27777 14.8353 2.19875 15.0261 2.19875 15.225V17.7863C2.19974 18.7875 2.59791 19.7474 3.30588 20.4554C4.01385 21.1633 4.97378 21.5615 5.975 21.5625H19.025C20.0262 21.5615 20.9861 21.1633 21.6941 20.4554C22.4021 19.7474 22.8003 18.7875 22.8013 17.7863V15.225C22.8013 15.0261 22.7222 14.8353 22.5816 14.6947C22.4409 14.554 22.2502 14.475 22.0513 14.475Z" fill="#CB212E" />
                <path d="M11.9675 2.65936C12.0372 2.58906 12.1202 2.53327 12.2116 2.49519C12.303 2.45711 12.401 2.43751 12.5 2.43751C12.599 2.43751 12.697 2.45711 12.7884 2.49519C12.8798 2.53327 12.9628 2.58906 13.0325 2.65936L17.3 6.92686C17.4191 7.07013 17.4806 7.25259 17.4725 7.43871C17.4644 7.62483 17.3874 7.80129 17.2563 7.93372C17.1253 8.06615 16.9497 8.14507 16.7637 8.15513C16.5776 8.16518 16.3945 8.10564 16.25 7.98811L13.25 4.98811V16.1369C13.25 16.3358 13.171 16.5265 13.0303 16.6672C12.8897 16.8078 12.6989 16.8869 12.5 16.8869C12.3011 16.8869 12.1103 16.8078 11.9697 16.6672C11.829 16.5265 11.75 16.3358 11.75 16.1369V4.99936L8.75 7.99936C8.60927 8.14009 8.4184 8.21915 8.21937 8.21915C8.02035 8.21915 7.82948 8.14009 7.68875 7.99936C7.54802 7.85863 7.46896 7.66775 7.46896 7.46873C7.46896 7.26971 7.54802 7.07884 7.68875 6.93811L11.9675 2.65936Z" fill="#CB212E" />
            </svg>

        </>
    )
}
export default Fram1